import BlockChildren from "./BlockChildren";
import type { BlockTreeProps } from "../type";

export default function Unsupported(props: BlockTreeProps) {
  const { block, children } = props;

  return (
    <div className="border-solid border-1 border-zinc-500 rounded-md my-8 pl-0">
      <p className="font-bold">Unsupported block: {block.type}</p>

      <pre className="h-32 overflow-scroll">
        {JSON.stringify(block, null, 2)}
      </pre>

      {children != null && <BlockChildren>{children}</BlockChildren>}
    </div>
  );
}
