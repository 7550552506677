import RichText from "..//RichText";
import type { QuoteBlockObjectResponse } from "@notionhq/client/build/src/api-endpoints";
import BlockChildren from "./BlockChildren";
import type { BlockTreeProps } from "../type";

export default function Quote(props: BlockTreeProps<QuoteBlockObjectResponse>) {
  const { block, children } = props;

  return (
    <div className="flex flex-row cursor-pointer items-start">
      <blockquote className="w-full">
        <p>
          <RichText richText={block.quote.rich_text} />
        </p>
        <BlockChildren>{children}</BlockChildren>
      </blockquote>
    </div>
  );
}
