import RichText from "../RichText";
import type { ToDoBlockObjectResponse } from "@notionhq/client/build/src/api-endpoints";
import { IoCheckbox, IoSquareOutline } from "react-icons/io5";
import BlockChildren from "./BlockChildren";
import type { BlockTreeProps } from "../type";

export default function ToDo(props: BlockTreeProps<ToDoBlockObjectResponse>) {
  const { block, children } = props;

  const checked = block.to_do.checked;

  const icon = checked ? (
    <IoCheckbox className="text-indigo-600" />
  ) : (
    <IoSquareOutline className="text-zinc-400" />
  );

  return (
    <>
      <div className="flex flex-row cursor-pointer items-start my-4">
        <div className="flex flex-0 w-8 h-7 justify-center items-center">
          {icon}
        </div>
        <div className="flex-1">
          <RichText richText={block.to_do.rich_text} />
        </div>
      </div>
      <div className="pl-8">
        <BlockChildren>{children}</BlockChildren>
      </div>
    </>
  );
}
