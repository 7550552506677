import type { PageMap, PageTree } from "@mrs/common";
import { minimalProperties, readPageProperty } from "@mrs/common";
import type { ReactElement } from "react";
import { useMemo } from "react";
import { Container } from "../Container.js";
import Hero from "../Hero";
import NewsletterPanel from "../NewsletterPanel.js";
import Prose from "../Prose.js";
import { SiteMapPanel } from "../SiteMapPanel.js";
import Spaced from "../Spaced.js";
import { HStack } from "../Stack.js";
import { TocPanel } from "../TocPanel.js";
import { NotionPageProvider } from "./NotionPageContext.js";
import BlockChildren from "./blocks/BlockChildren";

interface NotionPageProps {
  pageMap: PageMap;
  pageTree: PageTree;
  fullWidth?: boolean;
}

export default function NotionPage(props: NotionPageProps): ReactElement {
  const { pageMap, pageTree, fullWidth = false } = props;

  const { page, children } = pageTree;

  const title = useMemo(
    () =>
      readPageProperty(page.properties, minimalProperties.title) ?? undefined,
    [page.properties]
  );

  const coverImageUrl = useMemo(() => {
    switch (page.cover?.type) {
      case "external":
        return page.cover.external.url;

      case "file":
        return page.cover.file.url;

      case undefined:
      default:
        return undefined;
    }
  }, [page.cover]);

  return (
    <NotionPageProvider pageMap={pageMap} page={page}>
      <Spaced spacing="8">
        <Hero coverImageUrl={coverImageUrl} title={title} />

        <Container width="wide">
          <Spaced className="bg-white mx-auto" spacing="8">
            <HStack items="start" gap="8" collapsible={true}>
              <div className="flex-1">
                <Prose fullWidth={fullWidth}>
                  <BlockChildren>{children}</BlockChildren>
                </Prose>
              </div>
              <div className="w-56 flex-none flex flex-col gap-8">
                <TocPanel pageTree={pageTree} />
                <SiteMapPanel pageMap={pageMap} />
                <NewsletterPanel />
              </div>
            </HStack>
          </Spaced>
        </Container>
      </Spaced>
    </NotionPageProvider>
  );
}
