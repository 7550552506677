import RichText from "../RichText";
import type { ParagraphBlockObjectResponse } from "@notionhq/client/build/src/api-endpoints";
import BlockChildren from "./BlockChildren";
import type { BlockTreeProps } from "../type";

export default function Paragraph(
  props: BlockTreeProps<ParagraphBlockObjectResponse>
) {
  const { block, children } = props;

  return (
    <>
      <p>
        <RichText richText={block.paragraph.rich_text} />
      </p>
      <div className="ml-8">
        <BlockChildren>{children}</BlockChildren>
      </div>
    </>
  );
}
