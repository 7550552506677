import classNames from "classnames";
import type { ReactNode } from "react";
import "./Prose.css";

export interface ProseProps {
  fullWidth?: boolean;
  children: ReactNode;
}

export default function Prose(props: ProseProps) {
  const { fullWidth = false, children } = props;

  const className = classNames(
    "prose",
    fullWidth ? "full-width-prose" : "fixed-width-prose"
  );

  return <div className={className}>{children}</div>;
}
