import ButtonLink from "./ButtonLink.js";
import Heading from "./Heading.js";
import Panel from "./Panel.js";
import Spaced from "./Spaced.js";

export default function NewsletterPanel() {
  return (
    <Panel>
      <Spaced spacing="4">
        <Heading level="subsubsection">Newsletter</Heading>
        <p className="text-sm">
          Join our newsletter to receive updates and training announcements
          straight to your inbox:
        </p>
        <ButtonLink.External
          to="https://modularriversurvey.org/newsletter"
          width="full"
        >
          Subscribe
        </ButtonLink.External>
      </Spaced>
    </Panel>
  );
}
