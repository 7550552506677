import type { ImageBlockObjectResponse } from "@notionhq/client/build/src/api-endpoints";
import { useMemo } from "react";
import { richTextToPlain } from "../RichText";
import type { BlockTreeProps } from "../type";
import Caption from "./Caption";

export default function Image(props: BlockTreeProps<ImageBlockObjectResponse>) {
  const { block } = props;

  const src = useMemo(() => {
    switch (block.image.type) {
      case "external":
        return block.image.external.url;
      case "file":
        return block.image.file.url;
    }
  }, [block.image]);

  return (
    <figure className="w-full">
      <img
        src={src}
        alt={richTextToPlain(block.image.caption)}
        className="block w-full"
      />
      <figcaption>
        <Caption richText={block.image.caption} />
      </figcaption>
    </figure>
  );
}
