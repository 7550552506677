import { checkExhausted } from "@cartographerio/util";
import type { PageMeta } from "@mrs/common";
import type { LinkToPageBlockObjectResponse } from "@notionhq/client/build/src/api-endpoints";
import { useMemo } from "react";
import type { BlockTreeProps } from "../type";
import BlockChildren from "./BlockChildren";
import { useNotionPageMap } from "../NotionPageContext.js";

export default function LinkToPage(
  props: BlockTreeProps<LinkToPageBlockObjectResponse>
) {
  const { block, children } = props;

  const targetId: string = useMemo(() => {
    switch (block.link_to_page.type) {
      case "page_id":
        return block.link_to_page.page_id.replaceAll("-", "");
      case "database_id":
        return block.link_to_page.database_id.replaceAll("-", "");
      case "comment_id":
        return block.link_to_page.comment_id.replaceAll("-", "");
      default:
        return checkExhausted(block.link_to_page);
    }
  }, [block]);

  const page: PageMeta | null =
    useNotionPageMap().find(meta => meta.pageId === targetId) ?? null;

  return (
    <>
      {page == null ? null : <a href={page.path}>{page.title}</a>}
      <BlockChildren>{children}</BlockChildren>
    </>
  );
}
