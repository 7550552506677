import type { VideoBlockObjectResponse } from "@notionhq/client/build/src/api-endpoints";
import type { BlockTreeProps } from "../type";

export default function Video(props: BlockTreeProps<VideoBlockObjectResponse>) {
  const { block } = props;

  if (block.video.type === "external") {
    const url: string = block.video?.external?.url || "";

    if (url.includes("loom.com")) {
      const regex = /\/[\w]+\?/;
      const loomId = url.match(regex);

      if (!loomId || !loomId.length) {
        return <></>;
      }

      return <LoomEmbed loomId={loomId[0]} />;
    }
  }

  return <></>;
}

function LoomEmbed(props: { loomId: string }) {
  const { loomId } = props;
  return (
    <iframe
      className="w-full h-96"
      title="Video"
      src={`https://www.loom.com/embed${loomId}hide_owner=false&hide_share=true&hide_title=false&hideEmbedTopBar=true`}
    />
  );
}
