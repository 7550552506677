import type { BlockTreeProps } from "../type";
import type { BulletedListItemBlockObjectResponse } from "@notionhq/client/build/src/api-endpoints";
import RichText from "../RichText";
import BlockChildren from "./BlockChildren";

export default function BulletedListItem(
  props: BlockTreeProps<BulletedListItemBlockObjectResponse>
) {
  const { block, children } = props;

  return (
    <>
      <RichText richText={block.bulleted_list_item.rich_text} />
      <BlockChildren>{children}</BlockChildren>
    </>
  );
}
