import type { CodeBlockObjectResponse } from "@notionhq/client/build/src/api-endpoints";
import { useMemo } from "react";
import RichText, { richTextToPlain } from "../RichText";
import type { BlockTreeProps } from "../type";
import BlockChildren from "./BlockChildren";
import Caption from "./Caption";

export function parseUrl(text: string) {
  try {
    return new URL(text);
  } catch (error) {
    return null;
  }
}

export default function CodeOrShortCode(
  props: BlockTreeProps<CodeBlockObjectResponse>
) {
  const { block, children } = props;

  const configUrl = parseUrl(richTextToPlain(block.code.caption));

  switch (configUrl?.protocol) {
    case "ignore:":
      return <IgnoreShortCode block={block}>{children}</IgnoreShortCode>;

    case "injecthtml:":
      return <InjectShortCode block={block}>{children}</InjectShortCode>;

    default:
      return <Code block={block}>{children}</Code>;
  }
}

export function Code(props: BlockTreeProps<CodeBlockObjectResponse>) {
  const { block, children } = props;

  return (
    <div className="flex flex-row cursor-pointer items-start my-4">
      <pre className="w-full">
        <RichText richText={block.code.rich_text} />
      </pre>
      <Caption richText={block.code.caption} />
      <BlockChildren>{children}</BlockChildren>
    </div>
  );
}

export function IgnoreShortCode(
  _props: BlockTreeProps<CodeBlockObjectResponse>
) {
  return null;
}

export function InjectShortCode(
  props: BlockTreeProps<CodeBlockObjectResponse>
) {
  const { block } = props;

  const code = useMemo(
    () => ({ __html: richTextToPlain(block.code.rich_text) }),
    [block.code.rich_text]
  );

  return <div dangerouslySetInnerHTML={code} />;
}
