import { mediaUrl } from "./media";
import { checkExhausted } from "@cartographerio/util";
import { formatBlockId, formatPageId } from "@mrs/common";
import type { FileBlockObjectResponse } from "@notionhq/client/build/src/api-endpoints";
import { useMemo } from "react";
import { MdOutlineFileDownload } from "react-icons/md";
import type { BlockTreeProps } from "../type";
import BlockChildren from "./BlockChildren";
import Caption from "./Caption";
import { useNotionPageId } from "../NotionPageContext.js";

const FILENAME_REGEX = /([\w -]*).(jpg|png|pdf)/;

export default function File(props: BlockTreeProps<FileBlockObjectResponse>) {
  const { block, children } = props;

  const filename = useMemo(() => {
    switch (block.file.type) {
      case "external": {
        const extracted = block.file.external.url.match(FILENAME_REGEX);
        const caption = (extracted?.length && extracted[0]) || "Download";
        return caption;
      }
      case "file": {
        const extracted = block.file.file.url.match(FILENAME_REGEX);
        const caption = (extracted?.length && extracted[0]) || "Download";
        return caption;
      }
      default:
        return checkExhausted(block.file);
    }
  }, [block.file]);

  const pageId = useNotionPageId();

  const url = useMemo(
    () => mediaUrl(formatPageId(pageId), formatBlockId(block.id), "content"),
    [block.id, pageId]
  );

  // HACK: Notion doesn't give us the labels from uploaded files,
  // so we "recreate" them by replacing underscores in the filename with spaces:
  const label = useMemo(
    () =>
      filename
        .replaceAll(/__/g, " - ")
        .replaceAll(/_/g, " ")
        .replace(/[.][a-z]+$/i, ""),
    [filename]
  );

  return (
    <div>
      <FileLink url={url} label={label} />
      <Caption richText={block.file.caption} />
      <BlockChildren>{children}</BlockChildren>
    </div>
  );
}

interface FileLinkProps {
  url: string;
  label: string;
}

function FileLink(props: FileLinkProps) {
  const { url, label } = props;

  return (
    <div className="my-4">
      <p className="!my-2">
        <a target="_blank" className="flex" href={url} rel="noreferrer">
          <span className="text-zinc-500">
            <MdOutlineFileDownload className="flex-0 mt-1" size="22" />
          </span>
          <span className="flex-1 min-w-0">{label}</span>
        </a>
      </p>
    </div>
  );
}
