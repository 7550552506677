import { formatBlockId, formatPageId } from "@mrs/common";
import type { PdfBlockObjectResponse } from "@notionhq/client/build/src/api-endpoints";
import { useMemo } from "react";
import PdfViewer from "../../PdfViewer";
import type { BlockTreeProps } from "../type";
import BlockChildren from "./BlockChildren";
import Caption from "./Caption";
import { mediaUrl } from "./media";
import { useNotionPageId } from "../NotionPageContext.js";

export default function Pdf(props: BlockTreeProps<PdfBlockObjectResponse>) {
  const { block, children } = props;

  const pageId = useNotionPageId();

  const url = useMemo(
    () => mediaUrl(formatPageId(pageId), formatBlockId(block.id), "content"),
    [block.id, pageId]
  );

  return (
    <div>
      <PdfViewer url={url} />
      <Caption richText={block.pdf.caption} />
      <BlockChildren>{children}</BlockChildren>
    </div>
  );
}
