import { checkExhausted } from "@cartographerio/util";
import type {
  Heading1BlockObjectResponse,
  Heading2BlockObjectResponse,
  Heading3BlockObjectResponse,
  RichTextItemResponse,
} from "@notionhq/client/build/src/api-endpoints";
import { useMemo } from "react";
import type { HeadingLevel } from "~/components/Heading.js";
import Heading from "~/components/Heading.js";
import RichText from "../RichText";
import type { BlockTreeProps } from "../type";
import BlockChildren from "./BlockChildren";

export default function HeadingBlock(
  props: BlockTreeProps<
    | Heading1BlockObjectResponse
    | Heading2BlockObjectResponse
    | Heading3BlockObjectResponse
  >
) {
  const { block, children } = props;

  const [level, richText] = useMemo<
    [HeadingLevel, RichTextItemResponse[]]
  >(() => {
    switch (block.type) {
      case "heading_1":
        return ["section", block.heading_1.rich_text];
      case "heading_2":
        return ["subsection", block.heading_2.rich_text];
      case "heading_3":
        return ["subsubsection", block.heading_3.rich_text];
      default:
        return checkExhausted(block);
    }
  }, [block]);

  const anchor = useMemo(() => headingAnchor(richText), [richText]);

  return (
    <>
      <Heading level={level} anchor={anchor}>
        <RichText richText={richText} />
      </Heading>
      <BlockChildren>{children}</BlockChildren>
    </>
  );
}

export function headingText(richText: RichTextItemResponse[]): string {
  return richText.map(rich => rich.plain_text).join("");
}

export function headingAnchor(richText: RichTextItemResponse[]): string {
  return headingText(richText)
    .replaceAll(/[^a-zA-Z0-9]+/g, "")
    .toLowerCase();
}
