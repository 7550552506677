import RichText from "../RichText";
import type { EmbedBlockObjectResponse } from "@notionhq/client/build/src/api-endpoints";
import BlockChildren from "./BlockChildren";
import type { BlockTreeProps } from "../type";

export default function Embed(props: BlockTreeProps<EmbedBlockObjectResponse>) {
  const { block, children } = props;

  return (
    <div className="flex flex-row cursor-pointer items-start my-4">
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe src={block.embed.url} className="w-full" />
      <RichText richText={block.embed.caption} />
      <BlockChildren>{children}</BlockChildren>
    </div>
  );
}
