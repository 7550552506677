import type { PageMap } from "@mrs/common";
import { formatPageId } from "@mrs/common";
import { Link } from "@remix-run/react";
import type { ReactElement, ReactNode } from "react";
import { useMemo } from "react";
import { useNotionPageMap } from "./NotionPageContext.js";

interface PageMapLinkProps {
  className?: string;
  href: string;
  children: ReactNode;
}

export default function PageMapLink(props: PageMapLinkProps): ReactElement {
  const { className, href, children } = props;

  const remappedHref = useRemappedHref(useNotionPageMap(), href);

  return remappedHref == null ? (
    <a className={className} href={href}>
      {children}
    </a>
  ) : (
    <Link className={className} to={remappedHref}>
      {children}
    </Link>
  );
}

const NOTION_HREF_REGEX = /^https?:\/\/notion.so\/([0-9a-f-]{32,36})$/;
const NOTION_PATH_REGEX = /^\/([0-9a-f-]{32,36})$/;

export function useRemappedHref(pageMap: PageMap, href: string): string | null {
  return useMemo(() => remapHref(pageMap, href), [href, pageMap]);
}

export function remapHref(pageMap: PageMap, href: string): string | null {
  const match = href.match(NOTION_HREF_REGEX) ?? href.match(NOTION_PATH_REGEX);

  if (match == null) {
    return null;
  } else {
    const pageId = formatPageId(match[1]);
    return pageMap.find(meta => meta.pageId === pageId)?.path ?? null;
  }
}
