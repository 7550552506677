import type { BlockId, MediaRole, PageId } from "@mrs/common";

export function mediaUrl(
  pageId: PageId,
  blockId: BlockId | null,
  role: MediaRole
): string {
  return blockId == null
    ? `/media/page/${pageId}/${role}`
    : `/media/block/${pageId}/${blockId}/${role}`;
}
