import type { ToggleBlockObjectResponse } from "@notionhq/client/build/src/api-endpoints";
import { useCallback, useState } from "react";
import { IoCaretDownSharp, IoCaretForwardSharp } from "react-icons/io5";
import RichText from "../RichText";
import type { BlockTreeProps } from "../type";
import BlockChildren from "./BlockChildren";

export default function Toggle(
  props: BlockTreeProps<ToggleBlockObjectResponse>
) {
  const { block, children } = props;

  const [expanded, setExpanded] = useState(false);

  const icon = expanded ? (
    <IoCaretDownSharp className="text-zinc-400" />
  ) : (
    <IoCaretForwardSharp className="text-zinc-400" />
  );

  const handleClick = useCallback(() => setExpanded(!expanded), [expanded]);

  return (
    <>
      <div
        className="flex flex-row cursor-pointer items-start my-4"
        onClick={handleClick}
      >
        <div className="flex flex-0 w-8 h-7 justify-center items-center">
          {icon}
        </div>
        <div className="flex-1">
          <RichText richText={block.toggle.rich_text} />
        </div>
      </div>
      <div className="pl-8">
        {expanded && <BlockChildren>{children}</BlockChildren>}
      </div>
    </>
  );
}
