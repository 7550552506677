import { checkExhausted } from "@cartographerio/util";
import type { DatabaseId, PageId, PageMap } from "@mrs/common";
import { formatDatabaseId, formatPageId } from "@mrs/common";
import type { RichTextItemResponse } from "@notionhq/client/build/src/api-endpoints";
import PageMapLink from "./PageMapLink";
import { useNotionPageMap } from "./NotionPageContext.js";

export interface RichTextProps {
  richText: RichTextItemResponse[];
}

export function richTextToPlain(richText: RichTextItemResponse[]): string {
  return richText.map(rich => rich.plain_text).join("");
}

export default function RichText(props: RichTextProps) {
  const { richText } = props;

  return (
    <>
      {richText.map((item, index) => {
        switch (item.type) {
          case "text":
            return (
              <Span
                key={index}
                href={item.href}
                annotations={item.annotations}
                content={item.text.content}
              />
            );

          case "equation":
            return null;

          case "mention":
            switch (item.mention.type) {
              case "user":
                return null;
              case "date":
                return null;
              case "link_preview":
                return null;
              case "template_mention":
                return null;
              case "page":
                return (
                  <Mention
                    key={index}
                    annotations={item.annotations}
                    targetId={formatPageId(item.mention.page.id)}
                  />
                );

              case "database":
                return (
                  <Mention
                    key={index}
                    annotations={item.annotations}
                    targetId={formatDatabaseId(item.mention.database.id)}
                  />
                );

              default:
                return checkExhausted(item.mention);
            }

          default:
            return checkExhausted(item);
        }
      })}
    </>
  );
}

interface MentionProps {
  annotations: RichTextItemResponse["annotations"];
  targetId: PageId | DatabaseId;
}

function Mention(props: MentionProps) {
  const { annotations, targetId } = props;

  const page =
    useNotionPageMap().find(meta => meta.pageId === targetId) ?? null;

  return page == null ? null : (
    <Span href={page.path} annotations={annotations} content={page.title} />
  );
}

interface SpanProps {
  href?: string | null;
  annotations: RichTextItemResponse["annotations"];
  content: string;
}

function Span(props: SpanProps) {
  const { href, annotations, content } = props;

  const className = annotationClasses(annotations);

  if (href != null) {
    return (
      <PageMapLink className={className} href={href}>
        {content}
      </PageMapLink>
    );
  } else {
    return <span className={className}>{content}</span>;
  }
}

function annotationClasses(annotations: RichTextItemResponse["annotations"]) {
  const { bold, italic, strikethrough, underline } = annotations;
  return [
    ...(bold ? ["font-bold"] : []),
    ...(italic ? ["italic"] : []),
    ...(strikethrough ? ["line-through"] : []),
    ...(underline ? ["underline"] : []),
  ].join(" ");
}
