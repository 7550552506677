import RichText from "../RichText";
import type { BlockTreeProps } from "../type";
import type { NumberedListItemBlockObjectResponse } from "@notionhq/client/build/src/api-endpoints";
import BlockChildren from "./BlockChildren";

export default function NumberedListItem(
  props: BlockTreeProps<NumberedListItemBlockObjectResponse>
) {
  const { block, children } = props;

  return (
    <>
      <RichText richText={block.numbered_list_item.rich_text} />
      <BlockChildren>{children}</BlockChildren>
    </>
  );
}
