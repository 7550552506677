import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import type { ReactElement } from "react";

interface PdfViewerProps {
  url: string;
}

export default function PdfViewer(props: PdfViewerProps): ReactElement {
  const { url } = props;

  const layoutPlugin = defaultLayoutPlugin({
    sidebarTabs: _defaultTabs => [],
  });

  return (
    <div className="w-full h-64">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
        <Viewer fileUrl={url} plugins={[layoutPlugin]} />
      </Worker>
    </div>
  );
}
