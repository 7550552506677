import { checkExhausted } from "@cartographerio/util";
import type { BlockTreeProps } from "../type";
import BulletedListItem from "./BulletedListItem";
import Code from "./Code";
import File from "./File";
import Heading from "./Heading";
import Image from "./Image";
import LinkToPage from "./LinkToPage";
import NumberedListItem from "./NumberedListItem";
import Paragraph from "./Paragraph";
import ToDo from "./ToDo";
import Toggle from "./Toggle";
import Unsupported from "./Unsupported";
import Embed from "./Embed";
import Quote from "./Quote";
import Callout from "./Callout";
import Divider from "./Divider";
import Video from "./Video";
import ColumnList from "./ColumnList";
import Column from "./Column";
import Pdf from "./Pdf";

export default function Block(props: BlockTreeProps) {
  const { block, children } = props;

  switch (block.type) {
    case "bulleted_list_item":
      return <BulletedListItem block={block}>{children}</BulletedListItem>;
    case "callout":
      return <Callout block={block}>{children}</Callout>;
    case "code":
      return <Code block={block}>{children}</Code>;
    case "embed":
      return <Embed block={block}>{children}</Embed>;
    case "file":
      return <File block={block}>{children}</File>;
    case "heading_1":
    case "heading_2":
    case "heading_3":
      return <Heading block={block}>{children}</Heading>;
    case "image":
      return (
        // eslint-disable-next-line jsx-a11y/alt-text
        <Image block={block}>{children}</Image>
      );
    case "link_to_page":
      return <LinkToPage block={block}>{children}</LinkToPage>;
    case "numbered_list_item":
      return <NumberedListItem block={block}>{children}</NumberedListItem>;
    case "paragraph":
      return <Paragraph block={block}>{children}</Paragraph>;
    case "pdf":
      return <Pdf block={block}>{children}</Pdf>;
    case "to_do":
      return <ToDo block={block}>{children}</ToDo>;
    case "toggle":
      return <Toggle block={block}>{children}</Toggle>;
    case "quote":
      return <Quote block={block}>{children}</Quote>;
    case "divider":
      return <Divider />;
    case "video":
      return <Video block={block} />;
    case "column_list":
      return <ColumnList block={block}>{children}</ColumnList>;
    case "column":
      return <Column block={block}>{children}</Column>;
    case "template":
    case "synced_block":
    case "child_page":
    case "child_database":
    case "equation":
    case "breadcrumb":
    case "table_of_contents":
    case "table":
    case "table_row":
    case "bookmark":
    case "audio":
    case "link_preview":
    case "unsupported":
      return <Unsupported block={block}>{children}</Unsupported>;
    default:
      checkExhausted(block);
  }

  return <></>;
}
