import { useMemo } from "react";
import "./Hero.css";
import defaultCoverImageUrl from "./HeroImage.jpg";
import Heading from "./Heading.js";

interface HeaderProps {
  title?: string;
  coverImageUrl?: string;
}

export default function Hero(props: HeaderProps) {
  const { title, coverImageUrl = defaultCoverImageUrl } = props;

  const style = useMemo(
    () => ({
      backgroundImage: `url(${coverImageUrl})`,
      backgroundSize: "cover",
    }),
    [coverImageUrl]
  );

  return title != null ? (
    <div className="hero" style={style}>
      <Heading level="page">{title}</Heading>
    </div>
  ) : null;
}
